<template>
	<div>
		<div class="box" v-loading.fullscreen.lock="fullscreenLoading">
			<Title title="我的工单" />
			<div class="ct_box">
				<div class="search_status_box flex">
					<div class="search_box flex">
						<order_time @get_time="get_time" />
						<order_search @change_text="change_text" />
						<el-select v-model="resource.title" @change="category_id_check" placeholder="请选择媒体类别">
							<el-option v-for="item in resourceList" :key="item.id" :value="item.title">
							</el-option>
						</el-select>
					</div>
					<orderStatus width="250" :status_list="status_list" @status_tab="status_tab"
						:order_status="my_order.order_status" :category_id="my_order.category_id" />
				</div>
				<div class="table">
					<el-table :data="tableData" style="width: 100%">
						<el-table-column label-class-name="custom-header-class" class-name="custom-column-cell"
							header-align="center" prop="sn" label="订单ID" width="200">
						</el-table-column>
						<el-table-column label-class-name="custom-header-class" class-name="custom-column-cell"
							header-align="center" prop="title" label="标题" width="auto">
						</el-table-column>
						<el-table-column label-class-name="custom-header-class" class-name="custom-column-cell"
							header-align="center" prop="desc" label="问题描述" width="200">
						</el-table-column>
						<el-table-column label-class-name="custom-header-class" class-name="custom-column-cell"
							header-align="center" prop="medium_title" label="媒介名称" width="200">
						</el-table-column>
						<el-table-column label-class-name="custom-header-class" class-name="custom-column-cell"
							header-align="center" prop="order_price" label="金额" width="200">
						</el-table-column>
						<el-table-column label-class-name="custom-header-class" class-name="custom-column-cell"
							header-align="center" label="状态" width="200">
							<template slot-scope="scope">
								<p class="untreated" v-if="item.status == 0">
									未处理
								</p>
								<p class="untreated_success" v-if="item.status == 1">
									已处理
								</p>
								<p class="untreated_refuse" v-if="item.status == 2">
									拒绝处理
								</p>
							</template>
						</el-table-column>
						<el-table-column label-class-name="custom-header-class" class-name="custom-column-cell"
							header-align="center" prop="ctime" label="提交时间" width="200">
						</el-table-column>
						<!-- <el-table-column label-class-name="custom-header-class" class-na	me="custom-column-cell"
							header-align="center" fixed="right" label="操作" width="100">
							<template slot-scope="scope">
								<el-button @click="handleClick(scope.row)" type="text" size="small">查看</el-button>
								<el-button type="text" size="small">编辑</el-button>
							</template>
</el-table-column> -->
					</el-table>
				</div>
				<!-- 分页器 -->
				<pagination :page="page" @pageChange="pageChange" :count="count" :total_page="total_page" />
			</div>
		</div>

	</div>
</template>

<script>
import Title from '@/components/UserInfoTitle'
import order_time from '@/components/order_time'
import order_search from '@/components/order_time'
import orderStatus from '@/components/orderStatus'
import { timeCut } from '@/util/timeCut'
import { mapState } from 'vuex'
export default {
	components: {
		Title,
		order_time,
		order_search,
		orderStatus
	},
	computed: {
		...mapState(['my_order'])
	},
	mounted() {
		// if (this.$route.query.status && this.$route.query.status == '已处理') {
		// 	this.my_order.order_status = {
		// 		id: 1,
		// 		title: '已处理',
		// 	}
		// }
		this.curlGet('/api/medium_category/list').then(res => {
			if (res.data.code) {
				this.resourceList = res.data.data
			}
		})
		this.curlGet('/api/package/list').then(res => {
			if (res.data.code) {
				this.packageList = res.data.data
			}
		})
		this.get_list()
	},
	data() {
		return {
			time: '',
			resource: {
				id: 0,
				title: '不限'
			},
			orderId: '',
			resourceList: [],
			packageList: [],
			status_list: [{
				id: '',
				title: '全部'
			}, {
				id: 0,
				title: '未处理'
			}, {
				id: 1,
				title: '已处理',
			},
			{
				id: 2,
				title: '拒绝处理',
			}
			],
			tableData: [],
			total_page: 0, //分页总页数
			page: 1,
			count: 0,
			fullscreenLoading: false, //loding
		}
	},
	methods: {
		pageChange(pageVal) {
			this.page = pageVal
			this.get_list()
		},
		get_time(time) {
			this.time = time || []
			this.get_list()
		},
		change_text(text) {
			this.title = text
			this.pageChange(1)
		},
		status_tab(id) {
			this.my_order.order_status = id
			this.pageChange(1)

		},
		LinkShow() {
			this.Link = true
		},
		wordOrderShow() {
			this.WorkOrder = true
		},
		get_list() {
			this.fullscreenLoading = true
			// 取媒体类别
			let data = {}
			data.page = this.page
			data.limit = 20
			if (this.my_order.order_status.id !== '') {
				data.status = this.my_order.order_status.id
			}
			if (this.time) {
				data.start_time = this.time[0] / 1000;
				data.end_time = this.time[1] / 1000;
			}
			if (this.resource.id != '') {
				data.category_id = this.resource.id
			}
			if (this.orderId !== '') {
				data.search = this.orderId
			}
			this.curlGet('/api/users/work', data).then(res => {
				if (res.data.code) {
					console.log(res.data);
					this.total_page = res.data.data.total_page
					this.count = res.data.data.count
					this.tableData = res.data.data.list
					this.tableData.forEach((item, index) => {
						this.tableData[index].createtime = timeCut(this.tableData[index].createtime * 1000)
						console.log(this.tableData[index]);
					})
					this.fullscreenLoading = false
				}
			})
		},
		category_id_check(val) {
			let resultArr = this.resource_list.filter((item, key) => {
				if (item.title == val) {
					this.resource.id = item.id
					this.resource.title = item.title
				}
			});
			this.get_list()
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/scss/MediaOrder';
@import '@/scss/placard_media';

::v-deep .el-input__inner {
	height: 30px;
}

::v-deep .el-input__icon {
	line-height: 30px;
}
</style>